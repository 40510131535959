const expectedCtorKey = Symbol()

export class AnalysisViewOptionModel {
  static riskAnalysis = new AnalysisViewOptionModel('riskAnalysis', expectedCtorKey)
  static estimatedFailures = new AnalysisViewOptionModel('estimatedFailures', expectedCtorKey)

  static from(value) {
    if (value === 'riskAnalysis') return AnalysisViewOptionModel.riskAnalysis
    if (value === 'estimatedFailures') return AnalysisViewOptionModel.estimatedFailures
    throw new Error(`Unknown analysis view option: ${value}`)
  }

  static isValid(value) {
    if (value === AnalysisViewOptionModel.riskAnalysis.value) return true;
    return value === AnalysisViewOptionModel.estimatedFailures.value;
  }

  constructor(value, ctorKey) {
    if (ctorKey !== expectedCtorKey) throw new Error('AnalysisViewOptionModel is not constructable.')
    this.value = value
    Object.freeze(this)
  }

  get isRiskAnalysis() {
    return this === AnalysisViewOptionModel.riskAnalysis
  }

  get isEstimatedFailures() {
    return this === AnalysisViewOptionModel.estimatedFailures
  }
}
